'use client';

import { usePathname } from 'next/navigation';
import React, { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';

import { Sentry } from '@/lib/sentry';

const REFRESH_TIME = process.env.NODE_ENV === 'development' ? 60 * 1000 : 30 * 60 * 1000; // 1 minute in development, 30 minutes in production
const TOKEN_NAME = 'tokenRefreshTimestamp';
// Paths that should not trigger a token refresh
const EXCLUDED_PATHS = ['/goodbye', '/register', '/welcome/login', '/check-in', '/external'];

const TokenRefreshContext = createContext<{ lastRefresh: number | null }>({ lastRefresh: null });

export const useTokenRefresh = () => useContext(TokenRefreshContext);

export const TokenRefreshProvider = ({ children }: PropsWithChildren) => {
    const [lastRefresh, setLastRefresh] = useState<number | null>(null);
    const pathname = usePathname();

    useEffect(() => {
        const storedTimestamp = localStorage.getItem(TOKEN_NAME);

        if (!storedTimestamp) {
            const now = Date.now();
            localStorage.setItem(TOKEN_NAME, now.toString());
            setLastRefresh(now);
        } else {
            setLastRefresh(parseInt(storedTimestamp, 10));
        }
    }, []);

    useEffect(() => {
        const checkAndRefreshToken = async () => {
            const now = Date.now();
            if (
                lastRefresh &&
                now - lastRefresh >= REFRESH_TIME &&
                pathname &&
                !EXCLUDED_PATHS.some((path) => pathname.startsWith(path))
            ) {
                // 30 minutes
                try {
                    await fetch('/api/auth/refresh');

                    localStorage.setItem(TOKEN_NAME, now.toString());
                    setLastRefresh(now);
                } catch (error) {
                    Sentry.captureException(error, {
                        tags: {
                            context: 'Error while refreshing token',
                        },
                    });
                }
            }
        };

        const intervalId = setInterval(checkAndRefreshToken, 60 * 1000); // Check every minute
        // Check immediately on first render
        checkAndRefreshToken();

        return () => clearInterval(intervalId);
    }, [lastRefresh, pathname]);

    return (
        <TokenRefreshContext.Provider value={{ lastRefresh }}>
            {children}
        </TokenRefreshContext.Provider>
    );
};
